// .storybook/manager.js

import { addons } from "@storybook/addons";
import { themes } from "@storybook/theming";

import logo from "./logo.svg";

addons.setConfig({
  theme: {
    brandTitle: "lustudio",
    brandUrl: "https://lustudio.dev",
    brandImage: logo,
    colorPrimary: "#8AFF80",
    ...themes.dark,
  },
});
